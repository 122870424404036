.app {
    background-color: rgb(234, 232, 234);
    position: relative;
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .app {
        position: relative;
        height: fit-content;
    }
}