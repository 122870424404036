.RichEditor-root {
  background: #fff;
  /* border: 1px solid #ddd; */
  font-family: 'Calibri', serif;
  font-size: 14px;
  padding: 15px;
  width: 80%;
  margin-left: 10%;
  /* margin-right: 2%; */
}

.RichEditor-editor {
  /* border-top: 1px solid #ddd; */
  border: none;
  cursor: text;
  font-size: 16px;
  margin-top: 0px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: -20px -15px -15px;
  padding: 4%;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

img {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgb(234, 232, 234);
}

.reactionsTab {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding-left: 5%;
  padding-right: 5%;
  margin-right: 2%;
  cursor: pointer;
  width: auto;
}

.postArticleDialogSection {
  padding: 4%;
}

.newsletterSubscribeDialog {
  border-radius: 20px;
  margin-top: 2%;
}

.subscriptionButton {
  margin-top: 1.5%;
  padding: 2.5%;
  border-radius: 20px;
  border: none;
  color: #fff;
  background-color: #3366ff;
  cursor: pointer;
}

.subscriptionButton:hover {
  background-color: #0040ff;
  box-shadow: 0px 0px 3px 1.5px rgba(0, 64, 255, 0.4);
  transition: 0.3s;
  color: #fff;
  cursor: pointer;
}

.styleMap {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: Inconsolata, Menlo, Consolas, monospace;
  font-size: 16;
  padding: 2;
}

.blogsDisplayDiv {
  padding-left: 10%;
  padding-right: 10%;
}

.setSorting {
  color: #fff;
  background-color: #3366ff;
  padding: 1.5%;
  border: none;
  border-radius: 50px;
  margin-right: 0.5%;
  cursor: pointer;
}

.unsetSorting {
  color: #000;
  background-color: #f5f5f5;
  padding: 1.5%;
  border: 1px solid #999;
  border-radius: 50px;
  margin-right: 0.5%;
  cursor: pointer;
}

.blogCard {
  width: 100%;
  margin-top: 2%;
  border-radius: 20px;
  box-shadow: 0px 0px 2.5px 1.25px rgba(0, 0, 0, 0.15);
}

.blogCardImage {
  width: 90%;
  border-radius: 10px;
  margin-top: 5%;
}


@media only screen and (max-width: 600px) {
  .RichEditor-root {
    margin-left: 0%;
    width: auto;
  }

  .postArticleDialogSection {
    flex-direction: column;
  }

  .reactionsDialog {
    width: 120%;
  }

  .newsletterSubscribeDialog {
    width: 170%;
    margin-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .subscriptionButton {
    margin-top: 2%;
    padding: 2.5%;
    border-radius: 20px;
    border: none;
    color: #fff;
    background-color: #3366ff;
  }

  .blogsDisplayDiv {
    padding-left: 2%;
    padding-right: 2%;
  }

  .blogTitle {
    font-size: small;
  }

  .blogDescription {
    font-size: x-small;
  }

  .blogDate {
    font-size: smaller;
  }
}