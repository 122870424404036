.mainHeader {
    /* background-image: linear-gradient(rgba(255,255,255,1), rgba(230,230,230,0.5)); */
    background-color: rgba(234, 232, 234, 1);
    width: 100%;
    height: 70px;
    flex-direction: row;
    border: none;
    padding: 0.5%;
    position: sticky;
    top: 0;
    z-index: 100;
}

.loaderElement {
    text-align: center;
    /* height: 80vh; */
    height: auto;
    padding: 120px 0;
    margin-right: auto;
}

.primaryDescriptionContainer {
    padding: 3%;
    margin-top: -5%;
    width: 100%;
}

.primaryDescriptionText {
    color: #000;
    font-weight: 400;
    font-size: 220%;
}

.mainCardContainer {
    padding: 3%;
}

.mainCard {
    background-color: #fff;
    width: 98%;
    border-radius: 20px;
    margin-top: -8%;
    padding: 1%;
}

.cardHeader {
    font-weight: 700;
    margin-left: 1.5%;
    margin-right: 1.5%;
}

.cardContent {
    font-size: small;
    margin-left: 1.5%;
    margin-right: 1.5%;
}

.questionsPopup {
    text-decoration: underline;
}

.questionsPopup:hover {
    cursor: pointer;
}

.inputArea {
    width: 100%;
}

.contactInput {
    border: none;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.contactInput:hover {
    border: 1px solid #cccccc;
}

.contactInput:focus {
    border: 1px solid #cccccc;
}

.modalButton {
    height: 40px;
    border: none;
    background-color: #3366ff;
    color: #fff;
    width: 35%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.modalButton:hover {
    cursor: pointer;
}

.acknowledgementModal .ant-modal-content {
    border-radius: 20px;
}

.disclaimer {
    font-size: xx-small;
}

.contactButton {
    border-radius: 20px;
    border: none;
    padding: 2.5%;
    background-color: #3366ff;
    color: #fff;
}

.contactButton:hover {
    background-color: #0040ff;
    box-shadow: 0px 0px 3px 1.5px rgba(0, 64, 255, 0.4);
    transition: 0.3s;
    color: #fff;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .mainHeaderTitle {
        margin-left: 3%;
        cursor: pointer;
    }

    .postHeaderContent {
        font-size: medium;
        margin-top: 5%;
        margin-left: 5%;
        color: rgba(0, 0, 0, 0.6);
    }

    .tradingPlatform {
        color: #000;
        font-weight: 700;
        font-size: 115%;
    }

    .modalButton {
        height: 40px;
        border: none;
        background-color: #3366ff;
        color: #fff;
        width: 46%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .footer {
        padding: 0.5%;
        margin-top: auto;
        /* position: relative; */
        bottom: 30 !important;
        width: 100%;
        text-align: center;
        background-color: #fff;
    }

    .footerText {
        font-size: xx-small;
        margin-left: 2%;
        margin-right: 2%;
    }

    .videoIllustrationSection {
        text-align: center;
    }

    .illustration {
        width: 100%;
    }

    .generalLayout {
        display: flex;
        flex-direction: column;
    }

    .contentSection {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .mainHeaderTitle {
        margin-left: 4.5%;
        cursor: pointer;
    }

    .postHeaderContent {
        font-size: medium;
        margin-top: 2.8%;
        margin-left: 3%;
        color: rgba(0, 0, 0, 0.6);
    }

    .tradingPlatform {
        color: #000;
        font-weight: 700;
        font-size: 105%;
    }

    .footer {
        padding: 0.5%;
        position: relative;
        bottom: 0 !important;
        width: 100%;
        text-align: center;
        background-color: #fff;
    }

    .footerText {
        font-size: xx-small;
        margin-left: 2%;
        margin-right: 2%;
    }

    .generalLayout {
        display: flex;
        flex-direction: row;
    }

    .contentSection {
        width: 150%;
        padding-left: 3%;
    }

    .videoIllustrationSection {
        text-align: center;
    }

    .illustration {
        width: 100%;
        margin-top: 15%;
        margin-left: -10%;
    }
}

@media only screen and (min-width: 992px) {
    .mainHeaderTitle {
        margin-left: 6%;
        cursor: pointer;
    }

    .postHeaderContent {
        font-size: medium;
        margin-top: 1.7%;
        margin-left: 3%;
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
    }

    .tradingPlatform {
        color: #000;
        font-weight: 700;
        font-size: 140%;
    }

    .footer {
        padding: 0.8%;
        margin-top: auto;
        width: 100%;
        height: auto;
        text-align: center;
        background-color: #fff;
    }

    .footerText {
        font-size: xx-small;
        margin-left: 2%;
        margin-right: 2%;
    }

    .generalLayout {
        display: flex;
        flex-direction: row;
    }

    .contentSection {
        width: 50%;
        height: fit-content;
        padding-left: 5%;
        margin-top: -0.5%;
        /* background-color: red; */
    }

    .illustration {
        margin-top: 5%;
        margin-left: -5%;
        width: 100%;
    }

    .videoIllustrationSection {
        text-align: center;
        padding: 0% 0%;
        /* background-color: blue; */
        height: fit-content;
    }
}